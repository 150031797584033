export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    customRender: (_, __, index) => index + 1,
  },
  {
    title: "产品名称",
    dataIndex: "name",
  },
  {
    title: "产品编号",
    dataIndex: "number",
  },
  {
    title: "产品条码",
    dataIndex: "barcode",
  },
  {
    title: "产品分类",
    dataIndex: "category_name",
  },
  {
    title: "产品规格",
    dataIndex: "spec",
  },
  {
    title: "产品单位",
    dataIndex: "unit_name",
  },
  {
    title: "库存数量",
    dataIndex: "inventory_quantity",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: 120,
    scopedSlots: { customRender: "action" },
  },
];
